<template>
  <section id="built" class="my-16 ">
    <div class="text-center section-title mb-16">
      The LMS that's built for every need
    </div>
    <v-row justify="center" align="center">
      <v-col cols="12" xl="7" lg="9">
        <v-row align="center" v-for="(item, i) in items" :key="i" :class="i == 1 ? 'flex-row-reverse' : ''">
          <v-col cols="12" lg="6">
            <v-img :src="`${$assetStorage}/${item.img}`"/>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="section-title mb-2 col-lg-12">{{item.title}}</div>
            <div class="noto secondary-3--text col-lg-10 desc mb-2">{{item.desc}}</div>
            <v-btn class="poppins text-capitalize primary--text btn" text>
              Learn more
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        img: `generaled.svg`,
        title: 'General Education',
        desc: 'Transform your learning digitally. Streamline all your school processes in one platform made especially for students, for teachers, and for parents.',
        route: ''
      },
      {
        img: `training.svg`,
        title: 'Professional Training',
        desc: 'Bringing you a platform that provides end to end solution in the training process for your employees, customers, or partners that allows.',
        route: ''
      },
    ]
  })
}
</script>