<template>
  <section id="home-products">
    <div class="text-center">
      <v-btn fab large color="success" style="margin-top: -150px" @click="goto('products')">
        <v-icon bottom size="36">mdi-chevron-down</v-icon>
      </v-btn>
    </div>
    <v-row justify="center" align="center" class="my-16" ref="products" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
      <v-col cols="12" xl="7" lg="8">
        <div class="text-center pb-16">
          <div class="header-title">OUR PRODUCTS</div>
          <div class="section-title ">Learn without Limits</div>
        </div>
        <v-row class="py-16">
          <v-col cols="12" lg="4" v-for="(item, i) in products" :key="i" class="text-center">
            <v-avatar tile size="100">
              <v-img :src="`${$assetStorage}/${item.icon}`"/>
            </v-avatar>
            <h3 class="mt-10 mb-2 poppins">{{item.title}}</h3>
            <p class="noto secondary-3--text col-md-10 mx-auto">{{item.description}}</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  data: () => ({
    products: [
      {
        title: 'Educat LMS',
        description:'A Learning Management System and content library that offers a variety of ways on how to learn through blended learning.',
        icon: `product-1.svg`,
        route: 'Product-LMS'
      },
      {
        title: 'Educat Library',
        description:'An online library that contains Digital Modules, Worksheets and Assessments aligned with DepEd’s K to 12 Curriculum.',
        icon: `product-2.svg`,
        route: 'Product-Library'
      },
      {
        title: 'Educat Rover',
        description:'An offline solution run by mini-computers that are equipped with wireless local area network for contactless file transfers.',
        icon: `product-3.svg`,
        route: 'Product-Rover'
      }
    ],
  }),
  methods: {
    goto(name) {
      var element = this.$refs[name];
      var top = element.offsetTop - 40;
      window.scrollTo(0, top);
    }

    
  }
}
</script>