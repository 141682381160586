<template>
  <section id="home">
    <Banner/>
    <Products/>
    <HomeCarousel/>
    <Built/>
    <Design/>
    <Count/>
    <Testimonials/>
    <Partners/>
  </section>
</template>

<script>
import Banner from '../../components/landing/home/Banner.vue'
import Products from '../../components/landing/home/Products.vue'
import HomeCarousel from '../../components/landing/home/HomeCarousel.vue'
import Built from '../../components/landing/home/Built.vue'
import Design from '../../components/landing/home/Design.vue'
import Count from '../../components/landing/home/Count.vue'
import Testimonials from '../../components/landing/home/Testimonials.vue'
import Partners from '../../components/landing/home/Partners.vue'

export default {
  components: {
    Banner,
    Products,
    HomeCarousel,
    Built,
    Design,
    Count,
    Testimonials,
    Partners
  },
  data: () => ({  }),
  methods: { }
}
</script>