<template>
  <section id="home-carousel" class="pb-16">
    <v-row justify="center" align="center">
      <v-col cols="12" xl="8" lg="10">
        <div class="features">
          <v-carousel hide-delimiters height="auto" prev-icon="" continuous cycle interval="5000">
            <v-carousel-item v-for="(item, i) in features" :key="i">
              <v-row class="mx-10">
                <v-col cols="12" lg="6"  >
                  <div class="ma-10">
                    <div class="features-title col-xl-9">
                      Do more with Educat  
                    </div>
                    <div class="col-xl-12">
                      <div class="d-flex">
                        <div class="features-title">
                          {{item.number}}
                        </div>
                        <div class="ml-10">
                          <div class="poppins white--text features-inner-title mb-2"
                            style="">{{item.title}}</div>
                          <p class="noto white--text" style="font-size: 15px">
                            {{item.description}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" lg="6" class="d-flex align-end">
                  <v-img :lazy-src="`${$assetStorage}/${item.photo}`" :src="item.photo"/>
                </v-col>
              </v-row>
            </v-carousel-item>
          </v-carousel>
          
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  data: () => ({
    features: [
      {
        number: '01',
        title: 'Offline Learning',
        description:
          'The ideal solution to empower learners with no access to an internet connection.',
        photo: `feature_1.png`
      },
      {
        number: '02',
        title: 'Supplementary Learning Materials',
        description:
          'Our printed and interactive learning materials are perfect to help strengthen your learning.',
        photo: `feature_2.png`
      },
      {
        number: '03',
        title: 'All-in-one LMS Platform',
        description: 'All the solutions you need in one powe rful platform.',
        photo: `feature_3.png`
      }
    ],
  })
}
</script>