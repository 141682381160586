<template>
  <section id="banner" data-aos="fade-up" data-aos-delay="100" data-aos-duration="500" style="margin-top: -70px">
    <v-row class="home-banner" justify="center" align="center">
      <v-col cols="12" xl="9" lg="10">
        <v-row align="center" no-gutters justify="center">
          <v-col cols="12" lg="6">
            <div class="col-xl-9 col-lg-10 mx-auto">
              <div class="title ">
                The LMS that's built for your needs
              </div>
              <div class="subtitle my-5">
                Experience seamless communication, easy data migration, and secured user management in one awesome platform.
              </div>
              <v-btn class="btn" elevation="0" x-large width="250" to="/login">
                go to LMS portal
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <v-img :src="`${$assetStorage}/${bannerLink}`"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  data: () => ({
    bannerLink: `landing-banner.gif`
  })
}
</script>