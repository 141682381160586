<template>
  <section id="design-section" class="my-16">
    <v-row align="center" justify="center">
      <v-col cols="12" xl="9" lg="10">
        <div class="design pa-16">
          <v-row >
            <v-col cols="12" lg="6">
              <div class="section-title col-xl-9 col-lg-10" style="font-size: 40px">
                Designed to help you Work Smarter not Harder
              </div>
              <div class="noto secondary-3--text col-12" style="font-size: 18px;line-height: 183.85%;">
                Educat LMS offers the perfect end-to-end solution for schools and businesses. It also provides you with all the features needed to make learning and training easier. 
              </div>
              <v-btn class="white--text poppins ma-3" color="primary" elevation="0" x-large width="250" style="border-radius: 0px; font-size: 18px">
                BOOK A DEMO
              </v-btn>
            </v-col>
            <v-col cols="12" lg="6">
              <v-sheet max-height="390" color="transparent" class="overflow-y-auto overflow-hidden" id="sheet-scrollbar">
                <v-row class="col-lg-10 mx-auto">
                  <v-col cols="6" v-for="(item,i) in features" :key="i">
                    <v-sheet elevation="1" rounded="xl" max-width="170" class="pa-5 mx-auto text-center">
                      <v-avatar tile size="50">
                        <v-img :src="`${$assetStorage}/${item.icon}`"/>
                      </v-avatar>
                      <div class="poppins mt-5">{{item.title}}</div>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  data: () => ({
    features: [
      {
        title: 'Progress Monitoring',
        icon: `design/progress_monitoring.svg`
      },
      {
        title: 'Messaging',
        icon: `design/messaging.svg`
      },
      {
        title: 'Announcements',
        icon: `design/announcements.svg`
      },
      {
        title: 'Calendar',
        icon: `design/calendar.svg`
      },
      {
        title: 'Assessments',
        icon: `design/assessments.svg`
      },
      {
        title: 'Authoring Tool',
        icon: `design/authoring_tool.svg`
      },
      {
        title: 'User Management',
        icon: `design/user_management.svg`
      },
      {
        title: 'Section Management',
        icon: `design/section_management.svg`
      },
      {
        title: 'Security',
        icon: `design/security_.svg`
      },
      {
        title: 'Analytics',
        icon: `design/analytics.svg`
      },
      {
        title: 'Notifications',
        icon: `design/notifications.svg`
      },
      {
        title: 'Video Conference',
        icon: `design/video_conference.svg`
      },
      {
        title: 'Contactless Transaction',
        icon: `design/contactless_transaction.svg`
      },
      {
        title: 'Help Desk',
        icon: `design/help_desk.svg`
      }
    ],
  })
}
</script>

<style>

</style>