<template>
  <section id="partners" class="py-16 my-16">
    <v-row align="center" justify="center">
      <v-col cols="12" xl="6" lg="8">
        <div class="text-center ">
          <div class="header-title">OUR HAPPY CLIENTS</div>
          <div class="section-title ">Our Partners</div>
        </div>
        <div id="sheet-scrollbar" class="d-flex align-center overflow-y-auto">
          <div class="mx-5 mt-10" v-for="n in 15" :key="n">
            <v-img max-height="100" max-width="100" :src="`${$assetStorage}/${clientImg}-${n+1}.png`"/>
          </div>
        </div>
      </v-col>
    </v-row> 
  </section>
</template>

<script>
export default {
  data: () => ({
    clientImg: `partners/partner`
  })
}
</script>