<template>
  <section id="testimonials" class="my-16">
    <v-carousel continuous cycle interval="5000" :show-arrows="false" hide-delimiters hide-delimiter-background height="100%">
      <v-carousel-item v-for="(item,i) in testimonials" :key="i">
        <v-row class="" align="center" justify="center">
          <v-col cols="12" xl="9" lg="10">
            <v-row align="center">
              <v-col cols="12" lg="6">
                <div class="">
                  <div class="section-title">
                    They Said It First
                  </div>
                  <div class="poppins my-10" style="font-size: 18px">{{item.content}}</div>
                  <div class="poppins font-weight-bold" style="font-size: 18px">{{item.author}}</div>
                  <div class="poppins">{{item.position}}</div>
                </div>
              </v-col>
              <v-col cols="12" lg="6" class="d-flex justify-end align-end">
                <v-img :src="`${$assetStorage}/${item.photo}`" max-width="500"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </section>
</template>

<script>
export default {
  data: () => ({
    testimonials: [
      {
        content:'Our E-Learning platform developed by PCI-Educat, made our services automated and convenient for our customers. They have never left us on the journey and they make sure that they attend to our needs. PCI Tech is a strategic partner that understands what its client and partner need. We enjoy working with them!',
        author: 'Maria Korina Bertulfo',
        position: 'CEO and Founder, Filipina Homebased Moms',
        photo: 'mk-img.png'
      },
      {
        content:"Scuola Maria is fortunate to work with PCI-Educat early on when the pandemic hit the country. My ideas and my curriculum design all came to life in the NEST — our exclusive LMS for our students. If not for PCI, we wouldn't be able to continue education at this very difficult time.",
        author: 'Ina Gonda-Ramos, MAE-CDE, LPT',
        position: 'Program Director of Scuola Maria',
        photo: 'ina-img.png'
      }
    ],
  })
}
</script>
