<template>
  <section id="count" class="py-16 ">
    <v-row align="center" justify="center">
      <v-col cols="12" xl="8" lg="10">
        <v-row>
          <v-col cols="12" lg="3" v-for="(item, i) in items" :key="i">
            <div class="text-center">
              <div class="num poppins">
                {{item.count}}
              </div>
              <div class="poppins mt-2" style="font-size: 18px">
                {{item.title}}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  data: () => ({
    items: [
      { title: 'Learning Materials Donated', count: '1,200' },
      { title: 'LMS Users', count: '5,500' },
      { title: 'Trained Professionals', count: '3,000' },
      { title: 'Lorem ipsum', count: '000' },
    ]
  })
}
</script>